/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable dot-notation */
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { ReactElement, ReactNode, useState } from 'react';

import { ErrorAlert } from '@components/Alerts/ErrorAlert';
import { Loading } from '@components/Alerts/Loading';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { StandardLayout } from '@components/Layouts/StandardLayout';
import { LoginForm } from '@components/LoginForm';
import {
  RewardsLink,
  TextLink,
} from '@components/Typography/TextLink/TextLink';
import { useLogin } from '@hooks/customer/useLogin';
import { isErrorResponse } from '@interfaces/BulkAPI';
import { getCustomerId } from '@lib/api/customer';
import { COOKIES } from '@lib/constants';
import { getErrorMessage } from '@lib/utils';

import { Button } from '../components/Button';
import { Container } from '../components/Container';

/**
 *  To enable the ability to put links in the messages,
 *  rather than return the server message,
 * we have some custom ones based on the code
 */
const ERROR_CODES = {
  4011: (
    <div>
      To ensure security of our customers' accounts, we require you to update
      your password and have emailed you with instructions. Please check your
      spam/junk mail folder or{' '}
      <TextLink href="/contact-us">
        reach out to our Customer Service team
      </TextLink>
      .
    </div>
  ),
  4012: (
    <div>
      To ensure security of our customers' accounts, we require you to update
      your password. We were unable to email you with instructions, so please
      follow the{' '}
      <TextLink href="/forgot-password">forgot your password</TextLink> process,
      or{' '}
      <TextLink href="/contact-us">
        reach out to our Customer Service team
      </TextLink>
      .
    </div>
  ),
  4013: (
    <div>
      You have been locked out of your account due to too many failed login
      attempts. Please reset your password to regain access via the{' '}
      <TextLink href="/forgot-password">forgot your password</TextLink> process.
      If you have any difficulties,{' '}
      <TextLink href="/contact-us">
        reach out to our Customer Service team
      </TextLink>
      .
    </div>
  ),
  4014: (
    <div>
      Your email address or password is incorrect. Please try again. If you've
      forgotten your sign in details, please reset your password via the{' '}
      <TextLink href="/forgot-password">forgot your password</TextLink> process.
    </div>
  ),
};

// Component
function CreateAccount() {
  return (
    <div className="bg-grey-light p-5 dark:bg-grey-darkest">
      <h2 className="italic">Create new account</h2>
      <p>Create an account with Bulk Nutrients and you'll be able to:</p>
      <ul className="my-4 list-disc pl-10">
        <li className="mb-0.5 text-sm">Add favourite products</li>
        <li className="mb-0.5 text-sm">
          Earn{' '}
          <RewardsLink data-testid="whey-cool-rewards-link">
            Whey Cool Points
          </RewardsLink>
        </li>
        <li className="mb-0.5 text-sm">Checkout faster</li>
        <li className="mb-0.5 text-sm">Save multiple shipping addresses</li>
        <li className="mb-0.5 text-sm">Access your order history</li>
        <li className="mb-0.5 text-sm">Track new orders</li>
      </ul>
      <Button href="/create-account" data-testid="create-account-link">
        Create Account
      </Button>
    </div>
  );
}

// eslint-disable-next-line no-restricted-syntax
export default function LoginPage(): ReactElement {
  const router = useRouter();
  const [submitted, setSubmitted] = useState(false);

  if (submitted) {
    router.push('/account');
    return (
      <div className="flex h-screen place-content-center">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Head>
        <title>Bulk Nutrients create or sign into an existing account</title>
        <meta
          name="title"
          content="Bulk Nutrients create or sign into an existing account"
        />
        <meta
          name="description"
          content="Log into your existing Bulk Nutrients account and access previous orders, favourites, saved shipping addresses , Whey Cool loyalty points and more"
        />
        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_SERVER_URL}/login`}
        />
      </Head>
      <Container>
        <Breadcrumbs
          crumbs={[
            { title: 'Home', path: '/' },
            { title: 'Login', path: '/login' },
          ]}
        />
      </Container>
      <Container as="main" id="main-content">
        <div className="mb-5 grid grid-cols-1 gap-[75px] sm:grid-cols-2 sm:gap-4">
          <div>
            <h1>Sign in</h1>
            <LoginForm onLogin={() => setSubmitted(true)} />
          </div>
          <CreateAccount />
        </div>
      </Container>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async ({
  params,
  req,
}) => {
  // Get customer id if available
  const { cookies } = req;
  const customerToken = cookies[COOKIES.customer];
  const customerId = customerToken && (await getCustomerId(customerToken));

  // If customer, redirect to account home
  if (customerId) {
    return {
      redirect: {
        destination: '/account/home',
        permanent: false,
      },
    };
  }

  // return empty props
  return { props: {} };
};

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return <StandardLayout>{page}</StandardLayout>;
};
